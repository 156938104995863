<template>
  <div class="on-body">
    <Waves />

    <form
      class="form-signin"
      @submit.prevent="submit"
    >
      <img
        src="@/assets/logo_black.png"
        class="logo pb-5"
      >

      <Transition name="slide">
        <div v-if="error || success">
          <div
            v-if="error"
            class="text-danger font-weight-bold"
          >
            {{ error }}
          </div>
          <div
            v-if="success"
            class="text-success"
          >
            {{ success }}
          </div>
        </div>
      </Transition>

      <input
        v-model="password"
        type="password"
        :disabled="loading || success"
        class="form-control my-3"
        autocomplete="new-password"
        :placeholder="$t('login.password')"
        required
      >

      <input
        v-model="confirmPassword"
        type="password"
        :disabled="loading || success"
        autocomplete="new-password"
        class="form-control"
        :placeholder="$t('login.confirmPassword')"
        required
      >

      <button
        v-if="loading"
        class="btn btn-primary btn-block mt-4"
        style="height: 38px"
      >
        <Loader
          color="#fff"
          size="20px"
        />
      </button>
      <button
        v-else
        class="btn btn-primary btn-block mt-4"
        type="submit"
      >
        {{ $t('login.changePassword') }}
      </button>


      <div class="cancel-link">
        <RouterLink to="/login" >
          {{ $t('login.cancel') }}
        </RouterLink>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Waves from './Waves';

export default {
  data: () => ({
    loading: false,
    password: '',
    confirmPassword: '',
    error: null,
    success: null,
  }),
  components: {
    Waves,
  },
  methods: {
    submit() {
      if (this.confirmPassword !== this.password) {
        this.error = this.$t('login.samePasswordsError');
        return;
      }
      if (this.confirmPassword.length < 5) {
        this.error = this.$t('error.tooShortPassword');
        return;
      }

      this.loading = true;

      return axios.post('/api/magiclink/resetpassword', {
        encodedEmail: this.$route.query.email,
        token: this.$route.query.code,
        password: this.password,
      })
        .then(() => {
          this.loading = false;
          this.success = this.$t('login.passwordUpdated');
          this.error = null;

          setTimeout(() => {
            this.$router.replace(`/login?email=${this.$route.query.email}&flow=password-reset`);
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          
          if (e.response && e.response.data && e.response.data.type) {
            this.error = this.$t(`error.type.${e.response.data.type}`);
          } else {
            this.error = this.$t('error.internalError');
          }
        });
    },
  },
};
</script>

<style>
  html, body {
    height: 100vh;
  }
</style>

<style lang="scss" scoped>
  $blue:    #0070ff;

  .cancel-link {
    padding-top: 25px;
    font-size: 13px;

    a {
      color: #666;
    }
  }

  .magic-link {
    line-height: 1;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }

  .btn {
    border-radius: 20px;
  }

  .logo {
    width: 250px;
    max-width: 100%;
  }

  .magic-link:hover, .close-ml-message:hover {
    color: $blue;
  }

  .on-body {
    height: 100%;
    width: 100%;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .form-control {
    font-weight: 300;
    border: none;
    transition: box-shadow 500ms;
    box-shadow: 1px 1px 4px rgba(darken($blue, 30), 0.15);
    &:focus {
      box-shadow: 1px 1px 6px rgba($blue, 0.45);
    }
  }

  .btn-sm {
    font-weight: 300;
  }

  .login-button {
    width: 200px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title {
    font-size: 60px;
    font-weight: 100;
    margin-bottom: 70px;
  }

  .form-signin {
    width: 100%;
    max-width: 430px;
    padding: 15px 40px;
    margin: 0 auto;
    padding-bottom: 50px !important;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 500px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }

</style>
