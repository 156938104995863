<template>
  <div class="on-body">
    <form
      v-if="!pendingAll"
      class="form-signin"
      @submit.prevent="submit"
    >
      <img
        src="@/assets/logo_black.png"
        class="logo pb-5"
      >

      <Transition name="slide">
        <div v-if="error || success">
          <div
            v-if="error"
            class="text-danger font-weight-bold"
          >
            {{ error }}
            <div v-if="tryProviders.length > 0" >
              {{ $t('login.tryWith') }}
              <span v-for="p in tryProviders" :key="p" >
              {{ p }}
              </span>
            </div>
          </div>

          <div
            v-if="success"
            class="text-success"
          >
            {{ success }}
          </div>
        </div>
      </Transition>

      <input
        ref="emailInput"
        v-model="email"
        type="email"
        :disabled="token"
        class="form-control my-3"
        autocomplete="email"
        :placeholder="$t('login.emailAdress')"
        required
      >

      <input
        ref="passwordInput"
        v-model="password"
        :disabled="confirmMagicLink"
        type="password"
        autocomplete="current-password"
        class="form-control"
        :placeholder="$t('login.password')"
        required
      >

      <button
        v-if="loading"
        class="btn btn-primary btn-block mt-4"
        style="height: 38px"
      >
        <Loader
          color="#fff"
          size="20px"
        />
      </button>
      <button
        v-else
        class="btn btn-primary btn-block mt-4"
        type="submit"
      >
        {{ $t('login.login') }}
      </button>

    </form>
    <Loader v-else-if="pendingAll" />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    email: null,
    token: null,
    password: null,
    language: null,
    phoneNumber: null,
    passwordConfirm: null,
    loading: false,
    error: null,
    success: null,
    termsAccept: false,
    confirmMagicLink: false,
    magicLinkToken: null,
    accessCheck: false,
    checkTimeout: null,
    pendingAll: false,
    hideLogin: false,
    returnUrl: null,
    tryProviders: [],
  }),
  methods: {
    validate(email) {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(email);
    },
    submit() {
      const validEmail = this.validate(this.email);
      if (!validEmail) {
        this.error = this.$t('error.type.InvalidEmail');
        return;
      }
      if (!this.email || this.email.length < 3) {
        this.error = this.$t('login.enterEmail');
        return;
      }

      this.login().catch((e) => {
        this.loading = false;
        
        const { data } = e.response;
        if (data.logins && data.logins.length > 0) {
          this.tryProviders = data.logins;
        }

        if (e.response && e.response.status === 401) {
          this.error = this.$t('error.wrongPassword');
        } else if (e.response && e.response.data && e.response.data.type) {
          this.error = this.$t(`error.type.${e.response.data.type}`);

          if (e.response.data.type === 'InvalidMagicLink') {
            this.token = null;
          }
        } else {
          this.error = this.$t('error.internalError');
        }
      });
    },
    login() {
      this.loading = true;

      return axios.post('/api/login', {
        email: this.email,
        password: this.password,
        returnUrl: this.returnUrl,
      })
        .then(({ data }) => {
          this.loading = false;
          if (data) {
            window.location = data.redirect || process.env.VUE_APP_MAIN_URL;
          }
        });
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);

    if (params.has('ReturnUrl')) {
      this.returnUrl = params.get('ReturnUrl');
    }
  },
};
</script>

<style>
  html, body {
    height: 100vh;
  }
</style>

<style lang="scss" scoped>
  $blue:    #0070ff;

  .waves {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .wave-up {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $blue;
  }

  .wave-down {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#waveGradient);
  }

  .wave-svg-down {
    position: absolute;
    right: 0;
    bottom: -160px;
    max-width: 50%
  }


  .wave-svg-up {
    position: absolute;
    left: 0;
    top: 0px;
    max-width: 35%;
  }

  .magic-link {
    line-height: 1;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }

  .btn {
    border-radius: 20px;
  }

  .logo {
    width: 250px;
    max-width: 100%;
  }

  .magic-link:hover, .close-ml-message:hover {
    color: $blue;
  }

  .on-body {
    height: 100%;
    width: 100%;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .form-control {
    font-weight: 300;
    border: none;
    transition: box-shadow 500ms;
    box-shadow: 1px 1px 4px rgba(darken($blue, 30), 0.15);
    &:focus {
      box-shadow: 1px 1px 6px rgba($blue, 0.45);
    }
  }

  .form-control:disabled {
    background-color: $blue;
    color: white;
    font-weight: 500;
    text-align: center;
  }

  .btn-sm {
    font-weight: 300;
  }

  .login-button {
    width: 200px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title {
    font-size: 60px;
    font-weight: 100;
    margin-bottom: 70px;
  }

  .form-signin {
    width: 100%;
    padding-bottom: 50px;
    max-width: 430px;
    padding: 15px 40px;
    margin: 0 auto;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 500px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }

</style>
