<template>
  <div class="on-body">
    <div class="waves">
      <svg
        viewBox="0 0 500 500"
        preserveAspectRatio="xMaxYMin meet"
        class="wave-svg-up"
      >
        <path
          class="wave-up"
          d="M0,0v259.5C167.6-0.1,301.7,204,418,145.9c43-21.5,55-80,44.2-145.9H0z"
        />
      </svg>

      <svg
        viewBox="0 0 500 500"
        preserveAspectRatio="xMaxYMin meet"
        class="wave-svg-down"
      >
        <linearGradient
          id="waveGradient"
          gradientUnits="userSpaceOnUse"
          x1="150.7566"
          y1="149.0599"
          x2="524.9991"
          y2="297.3633"
        >
          <stop
            offset="0"
            style="stop-color:#0070ff"
          />
          <stop
            offset="1"
            style="stop-color:#163eeb"
          />
        </linearGradient>
        <path
          class="wave-down"
          d="M500,8.1c-5,62.8-33.3,151.9-114.1,202.4C269.7,
          283,137.5,229.2,116.3,335.6C104.4,395.4,156.1,442,269.9,471
          c92.3,23.5,171.3-18.5,230.1-68.4V8.1z"
        />
      </svg>
    </div>

    <form 
      method="POST"
      action="/api/provision"
    >
      <img
        src="@/assets/logo_black.png"
        class="logo pb-5"
      >

      <div class="text-center font-weight-bold pb-3">
        {{ $t('login.finishSignUp') }}
      </div>
      <div>
        <input
          :value="email"
          disabled
          class="form-control mb-3"
        />
      </div>

      <div class="mb-4">
        <div class="small text-secondary pb-1 text-left">
          {{ $t("login.forSmsNotifications") }}:
        </div>
        <input
          v-model="phoneNumber"
          type="tel"
          name="phoneNumber"
          autocomplete="tel"
          class="form-control"
          :placeholder="$t('login.phoneNumber')"
        />
      </div>

      <div class="custom-control custom-checkbox mt-3">
        <input
          id="customCheck1"
          v-model="termsAccept"
          type="checkbox"
          class="custom-control-input"
          required
        />
        <label class="custom-control-label" for="customCheck1">
          {{ $t("login.accept") }}
          <a href="https://pagaspot.com/docs/regulamin.pdf" target="_blank">
            {{ $t("login.termsAndConditions") }}
          </a>
        </label>
      </div>

      <div>
        <button
          class="btn btn-primary btn-block mt-3"
          type="submit"
        >
          {{ $t("login.register") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    phoneNumber: '',
    termsAccept: false,
    pending: false,
    email: '',
  }),
  created() {
    this.email = atob(this.$route.query.email);
  },
};
</script>

<style lang="scss" scoped>
  $blue:    #0070ff;

  .waves {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .wave-up {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $blue;
  }

  .wave-down {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#waveGradient);
  }

  .wave-svg-down {
    position: absolute;
    right: 0;
    bottom: -160px;
    max-width: 50%
  }


  .wave-svg-up {
    position: absolute;
    left: 0;
    top: 0px;
    max-width: 35%;
  }

  .logo {
    width: 350px;
    max-width: 100%;
  }

  .on-body {
    height: 100%;
    width: 100%;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }


</style>