<template>
  <ClipLoader
    :color="color || '#0070ff'"
    :size="size || '30px'"
  />
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader';

export default {
  props: {
    color: String,
    size: String,
  },
  components: {
    ClipLoader,
  },
};
</script>

<style>

</style>
