import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n';
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue'

import './styles/app.scss';
import Loader from './utils/Loader';

import Login from './pages/Login';
import PasswordLogin from './pages/PasswordLogin';
import MagicLink from './pages/MagicLink';
import ResetPassword from './pages/ResetPassword';
import Consent from './pages/Consent';

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.component('Loader', Loader);
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/login', component: Login },
    { path: '/passwordlogin', component: PasswordLogin },
    { path: '/resetpassword', component: ResetPassword },
    { path: '/magiclink', component: MagicLink },
    { path: '/consent', component: Consent },
    { path: '/', redirect: '/login' },
    { path: '*', redirect: () => {
        location.href = process.env.VUE_APP_MAIN_URL;
      }
    }
  ]
})

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')