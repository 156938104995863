import Vue from 'vue';
import VueI18n from 'vue-i18n';


const pl = {
  login: {
    login: 'Zaloguj',
    register: 'Zarejestruj się',
    validated: 'Zweryfikowano',
    forgetPass: 'Zapomniałem hasła',
    emailAdress: 'Adres email',
    password: 'Hasło',
    confirmPassword: 'Potwierdź hasło',
    changePassword: 'Zmień hasło',
    passwordUpdated: 'Hasło zmienione',
    accept: 'Zaakceptuj',
    termsAndConditions: 'regulamin',
    samePasswordsError: 'Hasła muszą być identyczne!',
    confirmViaEmail: 'Potwierdź dostęp przez maila.',
    confirmViaEmailCloseTab: 'Potwierdź dostęp przez maila. \n Nie zamykaj tej karty',
    checkSpam: 'Sprawdź czy nie trafił do spamu',
    enterEmail: 'Podaj adres email',
    validating: 'Potwierdzanie...',
    closeThisWindow: 'Możesz zamknąć to okno',
    invitationExpired: 'Zaproszenie wygasło',
    send: 'Wyślij',
    phoneNumber: 'Numer telefonu',
    forSmsNotifications: 'Opcjonalne, do powiadomień sms',
    tryWith: 'Spróbuj zalogować się używając',
    finishSignUp: 'Dokończ rejestrację',
    cancel: 'Anuluj',
  },
  error: {
    internalError: 'Błąd serwera. Spróbuj ponownie później 😒',
    wrongPassword: 'Błędne hasło!',
    enterName: 'Wprowadź imię i nazwisko klienta!',
    selectClients: 'Wybierz klientów',
    enterPaymentName: 'Podaj tytuł płatności',
    enterPaymentAmount: 'Podaj kwotę wpłaty',
    invalidAmount: 'Nieprawidłowa kwota',
    importImpossible: 'Import się nie powiódł',
    tooShortPassword: 'Za krótkie hasło (min. 5 znaków)',
    type: {
      CodeUsed: 'Kod został już użyty',
      CodeExpired: 'Kod wygasł',
      EmailExists: 'Podany email już istenieje. Wróć do logowania i użyj "Magic Link" jeśli nie pamiętasz hasła',
      UserNotVerified: 'Konto nie zostało zweryfikowane. Kliknij na "Magic Link" żeby zweryfikować adres email',
      Unknown: 'Nieznany błąd',
      InvalidEmail: 'Niepoprawny email',
      InvalidPassword: 'Niepoprawne hasło',
      InvalidRegisterPassword: 'Hasło powinno zawierać conajmniej 6 znaków',
      NoAccess: 'Brak dostępu',
      InvalidCode: 'Niepoprawny kod',
      InvalidMagicLink: 'Kod autoryzacyjny jest niewłaściwy lub wygasł',
    },
  },
}

const en = {
  login: {
    login: 'Sign in',
    register: 'Register',
    validated: 'Validated',
    forgetPass: 'Forget Password',
    emailAdress: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    changePassword: 'Change password',
    passwordUpdated: 'Password updated',
    accept: 'Accept',
    termsAndConditions: 'Terms and Conditions',
    samePasswordsError: 'Passwords must be identical',
    confirmViaEmail: 'We\'ve sent you confirmation email.',
    confirmViaEmailCloseTab: 'We\'ve sent you confirmation email.\n Don\'t close this tab.',
    checkSpam: 'Please check your spam inbox',
    enterEmail: 'Please enter your email address',
    validating: 'Validating...',
    closeThisWindow: 'You may now close this window',
    invitationExpired: 'Invitation has expired',
    send: 'Send',
    phoneNumber: 'Phone number',
    forSmsNotifications: 'Optional, for SMS notifications',
    tryWith: 'Try logging with',
    finishSignUp: 'Finish signing up',
    cancel: 'Cancel',
  },
  error: {
    internalError: 'Server Error. Try again later. 😒',
    wrongPassword: 'Wrong password!',
    enterName: 'Enter user name!',
    selectClients: 'Select clients',
    enterPaymentName: 'Enter payment name',
    enterPaymentAmount: 'Enter payment amount',
    invalidAmount: 'Invalid amount',
    importImpossible: 'Import unsuccessful',
    tooShortPassword: 'Too short password (min. 5)',
    type: {
      CodeUsed: 'Code has already been used',
      CodeExpired: 'Code has expired',
      EmailExists: 'Email already exists. Go back to login and use "Magic Link"',
      UserNotVerified: 'Account not verified. Click on "Magic Link" to verify your email',
      Unknown: 'Unknown error',
      InvalidEmail: 'Invalid email',
      InvalidPassword: 'Invalid password',
      InvalidRegisterPassword: 'Password should be at least 6 characters long',
      NoAccess: 'No access',
      InvalidCode: 'Invalid code',
      InvalidMagicLink: 'Authentication code invalid or expired',
    },
  },
}

Vue.use(VueI18n);

const browserLang = navigator.language || navigator.userLanguage || 'pl-PL';

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || browserLang.substring(0, 2),
  fallbackLocale: 'pl',
  messages: {
    pl,
    en,
  },
});

export default i18n;
