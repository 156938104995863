<template>
  <div class="on-body">
    <Waves />

    <form
      v-if="!pendingAll && !confirmMagicLink"
      class="form-signin"
      @submit.prevent="submit"
    >
      <img
        src="@/assets/logo_black.png"
        class="logo pb-5"
      >

      <Transition name="slide">
        <div v-if="error || success">
          <div
            v-if="error"
            class="text-danger font-weight-bold"
          >
            {{ error }}
            <div v-if="tryProviders.length > 0" >
              {{ $t('login.tryWith') }}
              <span v-for="p in tryProviders" :key="p" >
              {{ p }}
              </span>
            </div>
          </div>

          <div
            v-if="success"
            class="text-success"
          >
            {{ success }}
          </div>
        </div>
      </Transition>

      <input
        ref="emailInput"
        v-model="email"
        type="email"
        :disabled="token"
        class="form-control my-3"
        autocomplete="email"
        :placeholder="$t('login.emailAdress')"
        required
      >

      <Transition name="slide">
        <div class="d-flex my-3 justify-content-center">
          <input
            v-if="mode !== 'reset' && !confirmMagicLink"
            ref="passwordInput"
            v-model="password"
            :disabled="confirmMagicLink"
            type="password"
            :autocomplete="mode == 'register' ?
              'new-password' :'current-password'"
            class="form-control"
            :placeholder="$t('login.password')"
            required
          >
          <div
            v-if="mode === 'login' && !confirmMagicLink && !token"
            class="px-2 magic-link"
            @click="loginMagicLink('login')"
          >
            <i class="ion ion-ios-finger-print" />
            <div style="font-size: 10px ">
              Magic Link
            </div>
          </div>
        </div>
      </Transition>


      <Transition name="slide">
        <div v-if="mode === 'register'">
          <input
            v-model="passwordConfirm"
            type="password"
            autocomplete="new-password"
            class="form-control my-3"
            :placeholder="$t('login.confirmPassword')"
            required
          >

<!--          <div class="mb-4">-->
<!--            <div class="small text-secondary pb-1 text-left">-->
<!--              {{ $t('login.forSmsNotifications') }}:-->
<!--            </div>-->
<!--            <input-->
<!--              v-model="phoneNumber"-->
<!--              type="tel"-->
<!--              autocomplete="tel"-->
<!--              class="form-control"-->
<!--              :placeholder="$t('login.phoneNumber')"-->
<!--            >-->
<!--          </div>-->

          <div class="custom-control custom-checkbox mt-3">
            <input
              id="customCheck1"
              v-model="termsAccept"
              type="checkbox"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="customCheck1"
            >
              {{ $t('login.accept') }}
              <a
                href="https://pagaspot.com/docs/regulamin.pdf"
                target="_blank"
              >
                {{ $t('login.termsAndConditions') }}
              </a>
            </label>
          </div>
        </div>
      </Transition>

      <button
        v-if="loading"
        class="btn btn-primary btn-block mt-4"
        style="height: 38px"
      >
        <Loader
          color="#fff"
          size="25px"
        />
      </button>
      <button
        v-else-if="mode === 'login'"
        class="btn btn-primary btn-block mt-4"
        type="submit"
      >
        {{ $t('login.login') }}
      </button>
      <button
        v-else-if="mode === 'register'"
        class="btn btn-primary btn-block mt-3"
        type="submit"
      >
        {{ $t('login.register') }}
      </button>
      <button
        v-else
        class="btn btn-primary btn-block mt-3"
        type="submit"
      >
        {{ $t('login.send') }}
      </button>


      <div
        v-if="!token"
        class="row no-gutters mt-3"
      >
        <div class="col-12 col-sm-6 p-1">
          <button
            v-if="mode !== 'register'"
            class="btn btn-sm btn-outline-primary btn-block"
            @click.prevent="toRegister"
          >
            {{ $t('login.register') }}
          </button>
          <button
            v-else-if="!hideLogin"
            class="btn btn-sm btn-outline-primary btn-block"
            @click.prevent="toLogin"
          >
            {{ $t('login.login') }}
          </button>
        </div>
        <div class="col-12 col-sm-6 p-1">
          <button
            v-if="mode !== 'reset'"
            class="btn btn-sm btn-outline-primary btn-block"
            @click.prevent="toPasswordReset"
          >
            {{ $t('login.forgetPass') }}
          </button>
          <button
            v-else-if="!hideLogin"
            class="btn btn-sm btn-outline-primary btn-block"
            @click.prevent="toLogin"
          >
            {{ $t('login.login') }}
          </button>
        </div>
      </div>
      <div style="margin-bottom: 50px" />
      <div
        @click="loginExternal('Google')"
      >
        <img 
          class="login-button"
          src="@/assets/login/google-login.png" 
        />
      </div>
      <!-- <div
        @click="loginExternal('Apple')"
      >
        <button 
          class="btn btn-dark mt-2" 
          style="width: 200px"
        >
          Sign in with Apple
        </button>
      </div> -->
    </form>
    <Loader v-else-if="pendingAll" />
    <div v-else-if="confirmMagicLink">
      <h1 class="title">
        <img
          src="@/assets/logo_black.png"
          class="logo pb-5"
        >
      </h1>
      <div class="text-center">
        <h5>{{ email }}</h5>
        <span
          v-if="mode !== 'reset'"
          style="white-space: pre-wrap"
        >
          <span>{{ $t('login.confirmViaEmailCloseTab') }}</span>
        </span>
        <span	
          v-else	
          style="white-space: pre-wrap"	
        >	
          <span>{{ $t('login.confirmViaEmail') }}</span>	
        </span>
        <div class="small text-secondary pt-2">
          {{ $t('login.checkSpam') }}
        </div>
        <div v-if="mode !== 'register'">
          <div class="cancel-link">
            <a
              href="#"
              @click.prevent="confirmMagicLink = false"
            >
              <i class="ion ion-ios-arrow-back pr-2" />
              {{ $t('login.cancel') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Waves from './Waves';

export default {
  data: () => ({
    mode: 'login',
    email: null,
    token: null,
    password: null,
    language: null,
    phoneNumber: null,
    passwordConfirm: null,
    loading: false,
    error: null,
    success: null,
    termsAccept: false,
    confirmMagicLink: false,
    magicLinkToken: null,
    accessCheck: false,
    checkTimeout: null,
    pendingAll: false,
    hideLogin: false,
    returnUrl: null,
    tryProviders: [],
  }),
  components: {
    Waves,
  },
  methods: {
    toRegister() {
      this.mode = 'register';
    },
    toLogin() {
      this.mode = 'login';
    },
    toPasswordReset() {
      this.mode = 'reset';
    },
    loginExternal(scheme) {
      const returnUrl = encodeURIComponent(this.returnUrl || '/');
      location.href = `/api/external/challenge?scheme=${scheme}&returnUrl=${returnUrl}`
    },
    loginMagicLink(type, url) {
      if (!this.email || this.email.length < 3) {
        this.error = this.$t('login.enterEmail');
        return Promise.resolve();
      }
    
      // dummy url, to decode params
      var pUri = new URL(`https://pagaspot.com${decodeURIComponent(this.returnUrl)}`);
      const redirectUri = pUri.searchParams.get('redirect_uri');

      this.loading = true;
      return axios.post('/api/magiclink/send', {
        email: this.email, 
        type,
        returnUrl: url || redirectUri || process.env.VUE_APP_MAIN_URL,
      })
        .then(({ data }) => {
          this.accessCheck = true;
          this.confirmMagicLink = true;
          this.loading = false;

          if (this.mode !== 'reset') {
            this.magicLinkToken = data.token;
            this.checkLoginLoop();
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          if(response && response.data && response.data.type) {
            this.error = this.$t(`error.type.${response.data.type}`);
          } else {
            this.error = this.$t('error.internalError');
          }
        });
    },
    checkLoginLoop() {
      if (!this.magicLinkToken || !this.confirmMagicLink) return;
      if (this.checkTimeout) {
        clearTimeout(this.checkTimeout);
      }
      
      axios.post('/api/magiclink/check', {
        email: this.email,
        token: this.magicLinkToken,
      })
        .then(({ data }) => {
          if (data) {
            window.location = data.redirect || process.env.VUE_APP_MAIN_URL;
          }
        })
        .catch(() => {
          if (this.accessCheck) {
            this.checkTimeout = setTimeout(() => {
              this.checkLoginLoop();
            }, 2000);
          }
        });
    },
    validate(email) {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(email);
    },
    submit() {
      let request = null;
      const validEmail = this.validate(this.email);
      if (!validEmail) {
        this.error = this.$t('error.type.InvalidEmail');
        return;
      }
      if (!this.email || this.email.length < 3) {
        this.error = this.$t('login.enterEmail');
        return;
      }

      if (this.mode === 'login') {
        request = this.login();
      } else if (this.mode === 'register') {
        request = this.register();
      } else {
        request = this.loginMagicLink('forgotPassword', `${process.env.VUE_APP_MAIN_URL}/usersettings`);
      }
      if (request) {
        request.catch((e) => {
          this.loading = false;
          
          const { data } = e.response;
          if (data.logins && data.logins.length > 0) {
            this.tryProviders = data.logins;
          }

          if (e.response && e.response.status === 401) {
            this.error = this.$t('error.wrongPassword');
          } else if (e.response && e.response.data && e.response.data.type) {
            this.error = this.$t(`error.type.${e.response.data.type}`);

            if (e.response.data.type === 'InvalidMagicLink') {
              this.token = null;
            }
          } else {
            this.error = this.$t('error.internalError');
          }
        });
      }
    },
    login() {
      this.loading = true;

      return axios.post('/api/login', {
        email: this.email,
        password: this.password,
        returnUrl: this.returnUrl,
      })
        .then(({ data }) => {
          this.loading = false;
          if (data) {
            window.location = data.redirect || process.env.VUE_APP_MAIN_URL;
          }
        });
    },
    register() {
      if (this.password !== this.passwordConfirm) {
        this.error = this.$t('login.samePasswordsError');
        return null;
      }
      if (!this.password || this.password.length < 5) {
        this.error = this.$t('error.tooShortPassword');
        return null;
      }
      if (!this.termsAccept) {
        this.error = `${this.$t('login.accept')} ${this.$t('login.termsAndConditions')}!`;
        return null;
      }

      this.loading = true;
      return axios.post('/api/register', {
        email: this.email,
        password: this.password,
        token: this.token, 
        returnUrl: this.returnUrl,
        phoneNumber: this.phoneNumber,
      })
        .then(({ data }) => {
          if (data && data.isAuthenticated) {
            window.location = data.redirect || process.env.VUE_APP_MAIN_URL;
          } else {
            this.loginMagicLink('register');
            this.confirmMagicLink = true;
          }
        });
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);


    if (params.has('flow') && params.has('email')) {
      this.email = window.atob(params.get('email'));

    } else if (params.has('email')) {
      this.email = window.atob(params.get('email'));
      
      // if (params.has('token')) {
      //   this.token = atob(params.get('token'));
      // }

      this.mode = 'register';
      this.$refs.passwordInput.focus();
    } else {
      this.$refs.emailInput.focus();
    }

    if (params.has('hideLogin')) {
      this.hideLogin = true;
    }

    if (params.has('reset')) {
      this.mode = 'reset';
    }

    if (params.has('ReturnUrl')) {
      this.returnUrl = params.get('ReturnUrl');
    }
  },
};
</script>

<style>
  html, body {
    height: 100vh;
  }
</style>

<style lang="scss" scoped>
  $blue:    #0070ff;

  .magic-link {
    line-height: 1;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }

  .cancel-link {
    padding-top: 25px;
    font-size: 13px;

    a {
      color: #666;
    }
  }

  .btn {
    border-radius: 20px;
  }

  .logo {
    width: 350px;
    max-width: 100%;
  }

  .magic-link:hover, .close-ml-message:hover {
    color: $blue;
  }

  .on-body {
    height: 100%;
    width: 100%;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .form-control {
    font-weight: 300;
    border: none;
    transition: box-shadow 500ms;
    box-shadow: 1px 1px 4px rgba(darken($blue, 30), 0.15);
    &:focus {
      box-shadow: 1px 1px 6px rgba($blue, 0.45);
    }
  }

  .form-control:disabled {
    background-color: $blue;
    color: white;
    font-weight: 500;
    text-align: center;
  }

  .btn-sm {
    font-weight: 300;
  }

  .login-button {
    width: 200px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title {
    font-size: 60px;
    font-weight: 100;
    margin-bottom: 70px;
  }

  .form-signin {
    width: 100%;
    padding-bottom: 50px;
    max-width: 430px;
    padding: 15px 40px;
    margin: 0 auto;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 500px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }

</style>
