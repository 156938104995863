<template>
  <div class="on-body">
    <div>
      <div
        class="text-center"
        :class="error ? 'text-danger' : 'text-success'"
      >
        <div style="font-size: 40px">
          <i
            class="ion"
            :class="error ? 'ion-ios-close-circle' : 'ion-ios-checkmark-circle'"
          />
        </div>
        <h4 v-if="!error">
          {{ $t('login.validated') }}
        </h4>
        <h4 v-else>
          {{ error }}
        </h4>
        {{ $t('login.closeThisWindow') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    error: null,
  }),
  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('error')) {
      this.error = this.$t(`error.type.InvalidMagicLink`);
    }
  },
};
</script>

<style>
  html, body {
    height: 100%;
  }
</style>

<style lang="scss" scoped>
  

  .on-body {
    height: 100%;
    width: 100%;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

</style>
